.videoFooter {
    position: absolute;
    bottom: 50px;
    width: 100%
}

.footer-icons, .footer-text {
    position:relative;
    color: white;
    display: flex;
    justify-content: space-between;
}

.footer-text {
    justify-content: center;
}

.footer-text p {
    flex-grow: 1;
    text-align: center;
    font-weight: bold;
}
.videoFooter__text {
    /* padding-top: 1.5em; */
    padding-left: 1.5em;
    text-align: left;
}

.videoFooter__text > p {
    padding-bottom: 15px;
}

.videoFooter__button {
    padding: 20px;
    text-align: center;
}

.hiddenPlayContainer {
    width: 35px;
    height: 35px;
}

.link-text, .textNolink {
    color: white;
    font-weight: bold;
    text-decoration: none;
    background-color: rgb(191, 30, 32);
    padding: 0.25em 0.5em;
    border-radius: 5px;
    font-size: 1.1em;
    display: inline-block;
}

.userIcon {
    margin-right: 0.5em;
    margin-left: 2px;
    vertical-align: bottom;
}

.textNolink {
    background-color: transparent;
    /* text-shadow: -1px -1px 0 rgb(191, 30, 32), 1px -1px 0 rgb(191, 30, 32), -1px 1px 0 rgb(191, 30, 32), 1px 1px 0 rgb(191, 30, 32); */
    color: #fff;
    padding: 0;
}

.more-links {
    margin-bottom: 1em;
}