.videoHeader {
    width: 100%;
    height: 65px;
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-image: linear-gradient(to top, rgba(255,0,0,0), rgba(191,30,32,1)); */
    background-image: radial-gradient(circle, rgb(191, 30, 32) 8%, rgb(255, 0, 0, 0) 13%);
}

.jclogo {
    width: 35px;
    padding: 10px;
    flex: 1;

}

.videoHeader p {
    color: white;
    flex: 1;
    text-align: center;
}

.homeIcon.MuiSvgIcon-root {
    color: #fff;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
