.video {
    position: relative;
    background-color: white;
    width: 100%;
    height: 100%;
    scroll-snap-align: start;
}

.video__player {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.playIcon {
    position: absolute;
    left: 32%;
    top: 35%;
    color: #fff;
}

.MuiSvgIcon-fontSizeLarge.playIcon {
    font-size: 10em;
}