.metaDataPage {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5em;
}


h1 {
  margin-bottom: 2em;
}

.form-group label {
  font-weight: bold;
  font-size: 1.2em;
  width: 150px;
  display: inline-block;
  margin: 0.5em 0;
}

.form-group input {
  width: 100%;
  margin: 0.5em 0;
}

button, .link-home {
  color: white;
  font-weight: bold;
  text-decoration: none;
  background-color: rgb(191, 30, 32);
  padding: 0.25em 0.5em;
  border-radius: 5px;
  font-size: 1.1em;
  margin-top: 2em;
  width: 200px;
  text-align: center;
}

.link-home {
  display: block;
  background-color: green;
  width: 183px;
}

.success, .failure {
  color: green;
  font-size: 1.2em;
  border: 2px solid rgb(191, 30, 32);
  margin-bottom: 1em;
  padding: 1em;
  font-weight: bold;
}

.failure, .asterisk {  
  color: rgb(191, 30, 32);
}

@media screen and (min-width: 640px) {
  .metaDataPage {
    max-width: 60%;
  }

  .form-group input {
    width: 60%;
    margin: 0.5em 0 0.5em 2em;
  }
}


.notfound {
  margin-top: 8em;
  margin-bottom: 1em;
  color: white;
}

.notfound.link-home {
  width: 95%;
  margin-top: 1em;
}