html {
  scroll-snap-type: y mandatory;
}

.app {
  height: 100vh;
  background-color: black;
  display: grid;
  place-items: center;
}

.app__videos {
  position: relative;
  height: 800px;
  border-radius: 20px;
  overflow: scroll;
  width: 80%;
  max-width: 500px;
  scroll-snap-type: y mandatory;
}

@media (max-width: 800px) {
  .app__videos {
    height: 100vh;
    width: 100%;
    border-radius: 0;
  }
}

.app__videos::-webkit-scrollbar {
  display: none;
}

.app__videos {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.NotfoundPage {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 70%;
}


.NotfoundPage h1 {
  margin-top: 8em;
  margin-bottom: 1em;
  color: white;
}


.NotfoundPage a {
  color: white;
  font-weight: bold;
  text-decoration: none;
  background-color: rgb(191, 30, 32);
  padding: 0.25em 0.5em;
  border-radius: 5px;
  font-size: 1.1em;
  margin-top: 1em;
  text-align: center;
  display: block;
  background-color: green;
  width: 95%;
}

.loading {
  text-align: center;
  position: relative;
}

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

.spin {
  position: absolute;
  top: 70%;
  left: 67%;
}

.spin::before {
  animation: 0.8s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: rgb(191, 30, 32);
  border-radius: 50%;
  content: "";
  height: 65px;
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translate3d(-50%, -50%, 0);
  width: 65px;
  will-change: transform;
}

.comingsoonbg {
  background-image: url("https://mta-social-media.s3.eu-west-1.amazonaws.com/jalsaclips-coming-soon.jpeg");
  height: 100vh;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

@media (min-width: 640px) {
  .comingsoonbg {
    background-image: url("https://mta-social-media.s3.eu-west-1.amazonaws.com/jalsaclips-coming-soon-wide.jpg");
  }
}

.app__videos.comingsoon {
  height: 100%;
  width: 100%;
  max-width: 100%;
}

.videoSidebar {
  position: absolute;
  left: 26px;
  bottom: 28%;
}

.linkIcons {
  color: #fff;
}

.sidebar-icons div {
  margin-top: 5px;
}

.NotfoundPage .maintainance {
  font-size: 1.75em;
  text-align: center;
  margin-top: -3em;
}

.block {
  display: block;
  margin-top: 0.5em;
}